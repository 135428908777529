

a{
    text-decoration: none;
    color: #6fbbd3;
    font-size: 1rem;
    padding: 3%;
    margin: 0.5rem;
    border-style: solid;
    border-radius: 1rem;
    border-color:#6fbbd3;
}

a:hover, a:focus{
    color: white;
    background-color: lightblue;
    border-color:lightblue;
}

.backgroundHeightBox{
    height: 30vh;
}



@media only screen and (max-width: 600px) {
    .boxWithContent{
        display:flex;
        flex-direction: column;
        height:200vh;
        justify-content:flex-start;
        align-items:flex-start;
        font-weight: bolder;
        /* font-family:"Roboto"; */
        color: red;
        width: 100%;
        max-width: 100%;
    }
    
    .boxWithoutContent{
        height:200vh;
    }

    .margins{
        margin-top: 2rem;
    }
}

@media only screen and (min-width: 900px) {
    .boxWithContent{
        display:flex;
        flex-direction: column;
        height:200vh;
        justify-content:flex-start;
        align-items:flex-start;
        font-weight: bolder;
        /* font-family:"Roboto"; */
        width: 100%;
        max-width: 100%;
    }
    
    .boxWithoutContent{
        height:200vh;
    }

    .margins{
        margin-top: 3rem;
    }
}




.responsive{
    max-height:250px;
    max-width:100%;
    width:auto;
    height:auto;
}

@media only screen and (min-width: 990px){
    .imageWrapper{
        background-color:lightblue;
        border-radius:5rem;
        padding:2rem;
        margin-left:0.5%;
        margin-bottom: 5%;
        color:beige;
        transition: all 1s ease-in-out;
        width: 40%;
    }
}

@media only screen and (max-width: 600px){
    .imageWrapper{
        background-color:lightblue;
        border-radius:5rem;
        padding:2rem;
        margin-left:0.5%;
        margin-bottom: 5%;
        color:beige;
        transition: all 1s ease-in-out;
        width: 70%;
    }
}


.wrapperImageWrapper{
    display:flex;
    flex-direction:row;
    justify-content:space-evenly;
    align-items:flex-start;
    width:100%;
    flex-wrap:wrap;
}

.imageWrapperText{
    font-size: 1rem;
    font-weight: normal;
    width: 10rem;
    max-height: 0;
    overflow: clip;
    transition: all 1s ease-in-out;
    width: 100%;
}
  

    
.imageWrapper:hover > .imageWrapperText{
    max-height:  500px;
}

.imageWrapper:focus > .imageWrapperText{
    max-height: 1000px;
}



.imageWrapper:hover, .imageWrapper:focus{
    transform:scale(1.05);
}
